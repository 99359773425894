@font-face {
 font-family: "MyriadVariableConcept";
 src: url("../Assets/Font/MyriadVariableConcept.ttf") format("truetype");
 font-weight: 100 900;
 font-style: normal;
 font-display: swap;
}
* {
 font-family: "MyriadVariableConcept", sans-serif;
}
main {
 min-height: 80vh;
}
.rating span {
 margin: 0.1rem;
}
h3 {
 padding: 1rem 0;
}
h1 {
 font-size: 1.8rem;
 padding: 1rem 0;
}
h2 {
 font-size: 1.4rem;
 padding: 0.5rem 0;
}
.bgLandingPage {
 background-image: url(../Assets/Image/LogoFullColour.png);
 background-size: contain;
 background-position: center;
 background-repeat: no-repeat;
 width: 80vw;
 height: 80vh;
 display: flex;
 align-items: center;
 justify-content: center;
}
.bgHomePage {
 background-image: url(../Assets/Image/LogoFullColour.png);
 background-size: contain;
 background-position: center;
 background-repeat: no-repeat;
 width: 70vw;
 height: 70vh;
 display: flex;
}
.bgSignInPage {
 background-image: url(../Assets/Image/sinarutama_background_product.jpg);
 background-size: cover; /* Menjamin gambar memenuhi seluruh halaman */
 background-position: center;
 background-repeat: no-repeat;
 width: 102vw; /* Lebar layar */
 height: 100vh; /* Tinggi layar */
 display: flex;
 align-items: center;
 justify-content: center;
}

thead button {
 border: 0;
 border-radius: none;
 font-family: inherit;
 font-size: inherit;
 padding: 0.5em;
 margin-bottom: 1px;
 background-color: var(--dark);
 color: #ffffff;
}
thead button.ascending::after {
 content: "👇";
 display: inline-block;
 margin-left: 1em;
 background-color: var(--dark);
}

thead button.descending::after {
 content: "☝️";
 display: inline-block;
 margin-left: 1em;
 background-color: var(--dark);
}

tbody tr:hover {
 background-color: rgb(156, 225, 230);
}

Table {
 width: 100%;
 border-collapse: collapse;
}

table {
 width: 100%;
 border-collapse: collapse;
}

thead th {
 text-align: left;
 background-color: var(--main-color);
 color: white;
 text-transform: capitalize;
}
thead {
 position: sticky;
 top: 0;
}
.form-label {
 font-size: 0.8em;
}
.form-input {
 background-color: rgba(50, 50, 50, 0.1);
}
.disabled {
 background-color: rgba(36, 36, 36, 0.1);
}
.no-arrow::-webkit-inner-spin-button,
.no-arrow::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
.no-arrow {
 -moz-appearance: textfield;
}
Nav {
 font-weight: 500;
}
/* .navLink {
 color: white;
 font-weight: 500;
}
.navLink:hover {
 color: white;
 font-weight: 500;
 background-color: var(--hover-color);
} */

:root {
 --main-color: #dc3545; /*#ff0000; warna utama */
 --hover-color: #a12531;
 --secondary-color: #007bff; /* warna sekunder */
 --secondary-hover-color: #0056b3; /* warna hover sekunder */
 --dark: #343a40;
}
.td {
 border: "1px solid gray";
 border-color: #a5a5a5;
}
.attendance-hadir-button {
 color: rgb(6, 106, 6);
 background-color: rgb(169, 199, 169);
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.attendance-tidakhadir-button {
 color: rgb(6, 106, 6);
 background-color: rgb(169, 199, 169);
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.attendance-inclasstraining-button {
 color: rgb(47, 97, 106);
 /*background-color: rgb(169, 199, 169);*/
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.attendance-longshift-button {
 color: rgb(255, 255, 255);
 /*background-color: rgb(169, 199, 169);*/
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.attendance-overtimehours-button {
 color: rgb(108, 1, 214);
 /*background-color: rgb(169, 199, 169);*/
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.attendance-sakit-button {
 color: rgb(6, 106, 6);
 background-color: rgb(251, 217, 117);
 padding: 1px;
 transition: background-color 0.3s ease;
 border: none;
 min-width: 25px;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 min-height: 33px;
}
.button {
 color: white;
 border-radius: 5px;
 background-color: var(--main-color);
 padding: 2.5px 10px;
 transition: background-color 0.3s ease;
 border: none;
 font-weight: 500;
}
.button:hover,
.button:active {
 background-color: var(--hover-color);
 border-radius: 5px;
 font-weight: 500;
 color: white;
 border: none;
}
.button-secondary {
 color: white;
 border-radius: 5px;
 background-color: var(
  --secondary-color
 ); /* Ganti dengan warna sekunder yang sesuai */
 padding: 2.5px 10px;
 transition: background-color 0.3s ease;
 border: none;
 font-weight: 500;
}

.button-secondary:hover,
.button-secondary:active {
 background-color: var(
  --secondary-hover-color
 ); /* Ganti dengan warna hover sekunder yang sesuai */
 border-radius: 5px;
 font-weight: 500;
 color: white;
 border: none;
}
.sideNav {
 background-color: rgba(52, 58, 64, 1);
}
.comboBox {
 background-color: var(--main-color);
 color: white;
}
.loader {
 position: relative;
 width: 2.5em;
 height: 2.5em;
 transform: rotate(165deg);
}
.loader:before,
.loader:after {
 content: "";
 position: absolute;
 top: 50%;
 left: 50%;
 display: block;
 width: 0.5em;
 height: 0.5em;
 border-radius: 0.25em;
 transform: translate(-50%, -50%);
}
.loader:before {
 animation: before 2s infinite;
}
.loader:after {
 animation: after 2s infinite;
}

@keyframes before {
 0% {
  width: 0.5em;
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
   -1em 0.5em rgba(111, 202, 220, 0.75);
 }
 35% {
  width: 2.5em;
  box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
   0 0.5em rgba(111, 202, 220, 0.75);
 }
 70% {
  width: 0.5em;
  box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
   1em 0.5em rgba(111, 202, 220, 0.75);
 }
 100% {
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
   -1em 0.5em rgba(111, 202, 220, 0.75);
 }
}
@keyframes after {
 0% {
  height: 0.5em;
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
   -0.5em -1em rgba(233, 169, 32, 0.75);
 }
 35% {
  height: 2.5em;
  box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
   -0.5em 0 rgba(233, 169, 32, 0.75);
 }
 70% {
  height: 0.5em;
  box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
   -0.5em 1em rgba(233, 169, 32, 0.75);
 }
 100% {
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
   -0.5em -1em rgba(233, 169, 32, 0.75);
 }
}
/**
 * Attempt to center the whole thing!
 */
html,
body {
 height: 100%;
}

.loader {
 position: absolute;
 top: calc(50% - 1.25em);
 left: calc(50% - 1.25em);
}

.underline {
 text-decoration: underline;
}

.employee-card-setiabudi {
 background-color: rgba(52, 58, 64, 1);
 position: relative;
 overflow: hidden;
 border-radius: 10px; /* Tambahkan sudut melengkung */
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22); /* Efek bayangan keren */
}

.employee-card-setiabudi::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 48%; /* Tinggi background sesuai permintaan */
 background-image: url(../Assets/Image/OutletSetiabudi.jpg);
 background-size: cover; /* Memastikan gambar mencakup seluruh area */
 background-position: center; /* Memusatkan gambar */
 background-repeat: no-repeat; /* Menghindari pengulangan gambar */
 z-index: 1;
}

.employee-card-setiabudi .card-body {
 position: relative;
 z-index: 2; /* Konten tetap di atas background */
}

.employee-card-sudirman {
 background-color: rgba(52, 58, 64, 1);
 position: relative;
 overflow: hidden;
 border-radius: 10px; /* Tambahkan sudut melengkung */
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22); /* Efek bayangan keren */
}

.employee-card-sudirman::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 48%; /* Tinggi background sesuai permintaan */
 background-image: url(../Assets/Image/OutletSudirman.jpg);
 background-size: cover; /* Memastikan gambar mencakup seluruh area */
 background-position: center; /* Memusatkan gambar */
 background-repeat: no-repeat; /* Menghindari pengulangan gambar */
 z-index: 1;
}

.employee-card-sudirman .card-body {
 position: relative;
 z-index: 2; /* Konten tetap di atas background */
}

.employee-card-sriwijaya {
 background-color: rgba(52, 58, 64, 1);
 position: relative;
 overflow: hidden;
 border-radius: 10px; /* Tambahkan sudut melengkung */
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22); /* Efek bayangan keren */
}

.employee-card-sriwijaya::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 48%; /* Tinggi background sesuai permintaan */
 background-image: url(../Assets/Image/OutletSriwijaya.jpg);
 background-size: cover; /* Memastikan gambar mencakup seluruh area */
 background-position: center; /* Memusatkan gambar */
 background-repeat: no-repeat; /* Menghindari pengulangan gambar */
 z-index: 1;
}

.employee-card-sriwijaya .card-body {
 position: relative;
 z-index: 2; /* Konten tetap di atas background */
}

.employee-card-mahakam {
 background-color: rgba(52, 58, 64, 1);
 position: relative;
 overflow: hidden;
 border-radius: 10px; /* Tambahkan sudut melengkung */
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22); /* Efek bayangan keren */
}

.employee-card-mahakam::before {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 48%; /* Tinggi background sesuai permintaan */
 background-image: url(../Assets/Image/OutletMahakam.jpg);
 background-size: cover; /* Memastikan gambar mencakup seluruh area */
 background-position: center; /* Memusatkan gambar */
 background-repeat: no-repeat; /* Menghindari pengulangan gambar */
 z-index: 1;
}

.employee-card-mahakam .card-body {
 position: relative;
 z-index: 2; /* Konten tetap di atas background */
}

@import "rsuite/dist/rsuite.css";

linkContainer {
 padding: 0px;
}

/* CSS untuk menghilangkan panah pada input number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

input[type="number"] {
 -moz-appearance: textfield; /* Untuk Firefox */
}

.link-black {
 color: rgb(0, 0, 0); /* Change this to your desired color */
 text-decoration: none; /* Remove underline if desired */
}

.link-black:hover {
 color: rgb(0, 0, 0); /* Change color on hover */
}

.main-color {
 background-color: var(--main-color);
 border-radius: 5px;
 color: white;
 border: none;
}
.main-color:hover {
 background-color: var(--hover-color);
 border-radius: 5px;
 color: white;
 border: none;
}
.main-color:enabled {
 background-color: var(--main-color);
 border-radius: 5px;
 color: white;
 border: none;
}

.logo-container {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 position: relative;
 width: 30px; /* Adjust as needed */
 height: 30px; /* Adjust as needed */
}

.shirt-icon {
 font-size: 30px; /* Adjust size as needed */
 color: #a50000; /* #007bff Change color as needed */
}

.size-label {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 font-size: 8px; /* Adjust size as needed */
 font-weight: bold;
 color: #ffffff; /* Change color as needed */
}

.tetap {
 background-color: rgb(169, 199, 169);
 color: rgb(6, 106, 6);
 text-align: center;
}
.probation {
 background-color: rgb(168, 240, 245);
 color: rgb(8, 48, 181);
 text-align: center;
}

/* table */
.custom-table {
 background-color: #f8f9fa;
 border-collapse: collapse;
 font-size: 14px;
}

.custom-table tbody tr:hover {
 background-color: rgb(156, 225, 230);
}

.custom-table th,
.custom-table td {
 padding: 10px;
 vertical-align: middle;
}

.custom-table .sticky-header th {
 background-color: var(--dark);
 color: white;
 position: sticky;
 top: 0;
 z-index: 1;
 border-color: transparent;
}

.sticky-header {
 background-color: var(--dark);
 position: sticky;
 top: 0;
 background-color: white; /* Ensure background is the same as the table */
 z-index: 1; /* Ensure header stays above table content */
}

.custom-table .sort-btn {
 background: transparent;
 border: none;
 color: white;
 font-weight: bold;
 cursor: pointer;
 border-color: transparent;

 background: transparent;
 outline: none;
 box-shadow: none;
 border-color: transparent;
}

.custom-table .sort-btn:focus {
 outline: none;
}

.custom-table tbody tr:hover {
 background-color: rgb(156, 225, 230);
}

.employee-link {
 text-decoration: none;
 color: #007bff;
}

.employee-link:hover {
 text-decoration: underline;
}

.status-tetap {
 color: #28a745;
 font-weight: bold;
}

.status-probation {
 color: #ffc107;
 font-weight: bold;
}

.text-center {
 text-align: center;
}

.table-container {
 max-height: 500px;
 overflow-y: auto;
}

/*table end*/

.no-outline {
 outline: none;
}

.custom-button,
Button {
 transition: background-color 0.3s, color 0.3s;
 border-radius: 50%; /* Membuat button menjadi bulat */
 padding: 10px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Menambahkan bayangan */
 color: #ffffff;
}

.custom-button:hover,
Button:hover {
 background-color: rgba(
  255,
  255,
  255,
  0.2
 ); /* Ubah warna latar belakang saat hover */
 color: #ffffff; /* Ubah warna teks saat hover */
}

.custom-button:active,
Button:active {
 transform: scale(0.95); /* Menjadikan button sedikit lebih kecil saat diklik */
}

.normal-card {
 border-radius: 10px; /* Tambahkan sudut melengkung */
 box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22); /* Efek bayangan keren */
}

.modal-footer {
 display: flex;
 justify-content: space-between;
}

/* EmployeeTableView.css */
.custom-table thead th:hover {
 cursor: pointer;
}

.p-0 {
 padding: 0;
}

.cursor-pointer:hover {
 cursor: pointer;
}

@media (max-width: 576px) {
 .hidden-mobile {
  display: none;
 }
}

.modal-responsive {
 max-width: 100%; /* Lebar modal tidak lebih dari 95% dari lebar layar */
 min-width: 90%;
}

.text-right {
 text-align: right;
}

.text-light {
 color: #ffffff;
}

.text-dark {
 color: #212529;
}

.text-active {
 color: #007bff; /* Warna biru untuk teks yang dipilih */
}

/* CSS untuk sidebar */
.nav-item {
 color: #ffffff !important; /* Warna teks default untuk item */
}

.nav-item:hover {
 color: black !important; /* Warna teks saat hover */
}

.nav-item.active {
 color: #007bff !important; /* Warna biru saat item aktif */
}

.nav-item-non-active {
 color: #ffffff;
}

.nav-item-non-active:hover {
 color: black;
}

@media (max-width: 768px) {
 .nav-toggle {
  display: block;
 }
}

.chartjs-tooltip {
 background: #ffffff;
 color: #000000e9;
}

/* Popover footer styling */
.popover-footer {
 gap: 10px; /* Space between buttons */
 padding: 10px 0; /* Vertical padding */
 border-top: 1px solid #ddd; /* Subtle top border */
 background-color: #f9f9f9; /* Light background for the footer */
 border-radius: 0 0 0.25rem 0.25rem; /* Rounded corners at the bottom */
}

/* Styling for the individual buttons */
.popover-footer .btn {
 font-size: 0.875rem; /* Slightly smaller font for footer buttons */
 padding: 5px 10px; /* Button padding */
 min-width: 80px; /* Minimum width for buttons */
 transition: background-color 0.2s ease-in-out; /* Smooth background color transition */
}

/* Specific styles for Cancel and Save buttons */
.popover-footer .btn-secondary {
 background-color: #6c757d;
 border-color: #6c757d;
}

.popover-footer .btn-secondary:hover {
 background-color: #5a6268;
 border-color: #545b62;
}

.popover-footer .btn-primary {
 background-color: #007bff;
 border-color: #007bff;
}

.popover-footer .btn-primary:hover {
 background-color: #0056b3;
 border-color: #004085;
}

/* Add a shadow or hover effect for the entire popover */
.popover {
 box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
